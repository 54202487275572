<template>
  <CFooter>
    <div>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} rScheduling.</span
      >
    </div>
    <div class="ms-auto">
      Version 2.0.1
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
